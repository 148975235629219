import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
    Grid,
    Divider,
    CircularProgress,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import Search from './Search';
import GetZipButton from './GetZipButton';
import { useSelector } from 'react-redux';
import GitHubIcon from '@mui/icons-material/GitHub';
import RejectButton from 'components/RejectButton';
import ApproveButton from 'components/ApproveButton';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'name', disablePadding: true, label: 'Name' },
    { id: 'surname', disablePadding: true, label: 'Surname' },
    { id: 'email', disablePadding: true, label: 'Email' },
    { id: 'group', disablePadding: false, label: 'Applied For' },
    { id: 'phonenumber', disablePadding: true, label: 'Phone Number' },
    { id: 'actions', disablePadding: true, label: 'Actions' },
    { id: 'Status', disablePadding: true, label: 'Status' }
];

function AdminTableHead({ order, orderBy }) {
    return (
        <TableHead>
            <TableRow sx={{ '& th:not(:last-child)': { borderRight: '1px solid #f0f0f0' } }}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

AdminTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string
};

const fuseOptions = {
    includeScore: true,
    keys: ['id', 'name', 'surname', 'email']
};

export default function AdminTable() {
    const [isLoading, setLoading] = useState(false);
    const token = useSelector((state) => state.token);
    const [fetch, setFetch] = useState(true);
    const triggerFetch = () => setFetch(true);
    const [order] = useState('asc');
    const [orderBy] = useState('id');
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]); // Initialize with mock data
    const fuse = new Fuse(filteredRows, fuseOptions);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [groups, setGroups] = useState([]);

    const handleSearchChange = (e) => {
        if (!e.target.value) {
            setFilteredRows(rows);
            return;
        }

        const result = fuse.search(e.target.value);

        setFilteredRows(result.map((r) => r.item));
    };

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await axios.get(`/api/ApplicationGroup`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setGroups(response.data); // Assuming the response data is an array of groups
            } catch (error) {
                console.error('Failed to fetch groups:', error);
                // Optionally handle the error, e.g., show an error message
            }
        };

        fetchGroups();
        const fetchJobApplications = async () => {
            setLoading(true);
            try {
                const [applications] = await Promise.all([
                    axios.get(`/api/JobApplication/applicants`, { headers: { Authorization: `Bearer ${token}` } })
                ]);

                const enhancedApplications = applications.data.map((app) => ({
                    ...app
                }));

                setRows(enhancedApplications);
                setFilteredRows(enhancedApplications);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                Swal.fire('Error', 'Failed to fetch job applications', 'error');
            }
            setLoading(false);
        };

        fetchJobApplications();
    }, [fetch, token]);
    const handleStatusChange = (event) => {
        const selectedStatus = event.target.value;
        setSelectedStatus(selectedStatus);

        const newFilteredRows = rows.filter((row) => {
            const matchesGroup = selectedGroup === '' || row.groupName === selectedGroup;
            const matchesStatus = selectedStatus === '' || row.status.toString() === selectedStatus;
            return matchesGroup && matchesStatus;
        });

        setFilteredRows(newFilteredRows);
    };
    const handleGroupChange = (event) => {
        const selectedGroup = event.target.value;
        setSelectedGroup(selectedGroup);

        const newFilteredRows = rows.filter((row) => {
            const matchesGroup = selectedGroup === '' || row.groupName === selectedGroup;
            const matchesStatus = selectedStatus === '' || row.status.toString() === selectedStatus;
            return matchesGroup && matchesStatus;
        });

        setFilteredRows(newFilteredRows);
    };

    return (
        <Box>
            <Typography variant="h4" sx={{ mt: 4, mb: 1.5, ml: 1 }}>
                Applicants
            </Typography>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid item xs={5} lg={5} display="flex" justifyContent="flex-start">
                    <Search onChange={handleSearchChange} style={{ flexGrow: 1, height: '56px' }} />

                    <FormControl variant="outlined" sx={{ flexGrow: 1, minWidth: '200px', ml: 2 }}>
                        <InputLabel>Group</InputLabel>
                        <Select
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            label="Group"
                            fullWidth
                            sx={{ height: '35px', ml: 1, mb: 1 }}
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {groups.map((group) => (
                                <MenuItem key={group.id} value={group.id}>
                                    {group.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" sx={{ flexGrow: 1, minWidth: '200px', marginLeft: 2 }}>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            label="Status"
                            fullWidth
                            sx={{ height: '35px', ml: 1 }}
                        >
                            <MenuItem value="">
                                <em>ALL</em>
                            </MenuItem>
                            <MenuItem value={1}>Pending</MenuItem>
                            <MenuItem value={2}>Approved</MenuItem>
                            <MenuItem value={3}>Rejected</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider />
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' }
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-of-type': {
                            pl: 2
                        }
                    }}
                >
                    <AdminTableHead order={order} orderBy={orderBy} />
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell key="spinner" colSpan="6">
                                    <CircularProgress data-testid="spinner" sx={{ display: 'block', margin: 'auto' }} />
                                </TableCell>
                            </TableRow>
                        ) : !filteredRows.length ? (
                            <TableRow>
                                <TableCell key="notfound" colSpan="6">
                                    <Typography sx={{ p: 2, textAlign: 'center' }}>No matching result found</Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            stableSort(filteredRows, getComparator(order, orderBy)).map((row, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.surname}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.groupName}</TableCell>

                                        <TableCell>{row.phoneNumber}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => window.open(row.gitHubLink, '_blank')}
                                            >
                                                <GitHubIcon />
                                            </Button>
                                            <GetZipButton
                                                fileName={row.name + '_' + row.surname + '.zip'}
                                                downloadLink={row.downloadLink}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {row.status === 1 ? (
                                                <>
                                                    <Grid container direction="row" justifyContent="space-between">
                                                        <Grid>
                                                            <ApproveButton name={row.name} id={row.id} triggerFetch={triggerFetch} />
                                                        </Grid>
                                                        <Grid>
                                                            <RejectButton name={row.name} id={row.id} triggerFetch={triggerFetch} />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ) : row.status === 2 ? (
                                                <Typography color="primary">Approved</Typography>
                                            ) : row.status === 3 ? (
                                                <Typography color="error">Rejected</Typography>
                                            ) : (
                                                'Unknown Status'
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
