import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const location = useLocation();
    const [isSelected, setIsSelected] = useState(false);

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    // eslint-disable-next-line react/display-name
    let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch(activeItem({ openItem: [id] }));
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{ fontSize: '1rem' }} /> : false;

    // active menu item on page load
    useEffect(() => {
        const currentIndex = location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(activeItem({ openItem: [item.id] }));
            setIsSelected(true);
        } else {
            dispatch(activeItem({ openItem: ['/'] }));
            setIsSelected(false);
        }
    }, [location, dispatch, item.id]);

    const textColor = 'text.primary';
    const iconSelectedColor = 'primary.main';

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => itemHandler(item.id)}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: `${level * 28}px`,
                py: 1,
                '&:hover': {
                    bgcolor: 'primary.lighter'
                },
                '&.Mui-selected': {
                    bgcolor: 'primary.lighter',
                    borderRight: `2px solid ${theme.palette.primary.main}`,
                    color: iconSelectedColor,
                    '&:hover': {
                        color: iconSelectedColor,
                        bgcolor: 'primary.lighter'
                    }
                }
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                        {item.navTitle}
                    </Typography>
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
