import axios from 'axios';
import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { FileDownload } from '@mui/icons-material/index';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function GetZipButton({ fileName, downloadLink }) {
    const [loading, setLoading] = useState(false);
    const url = downloadLink;
    const token = useSelector((state) => state.tokenSlice.accessToken);
    const handleButtonClick = () => {
        setLoading(true);
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                response.headers['content-disposition'];
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setLoading(false);
            })
            .catch((error) => {
                console.error('An error occurred while retrieving the data:', error);
                setLoading(false);
            });
    };

    return (
        <Button onClick={handleButtonClick} disabled={loading}>
            {loading ? <CircularProgress size={20} /> : <FileDownload />}
        </Button>
    );
}

GetZipButton.propTypes = {
    fileName: PropTypes.string,
    downloadLink: PropTypes.string
};

export default GetZipButton;
