const breadcrumbRoutes = [
    {
        paths: ['User List'],
        title: 'User List',
        url: '/users'
    },
    {
        paths: ['Roles'],
        title: 'Roles',
        url: '/roles'
    },
    {
        paths: ['Companies'],
        title: 'Companies',
        url: '/companies'
    },
    {
        paths: ['User Profile'],
        title: 'User Profile',
        url: '/profile/:userId'
    },
    {
        paths: ['User Profile', 'Edit Profile'],
        title: 'Edit Profile',
        url: '/profile/:userId/edit'
    },
    {
        paths: ['Create Company'],
        title: 'Create Company',
        url: '/company/create'
    },
    {
        paths: ['Create User'],
        title: 'Create User',
        url: '/user/create'
    }
];

export default breadcrumbRoutes;
