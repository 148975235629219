import { createSlice } from '@reduxjs/toolkit';

const tokenSlice = createSlice({
    name: 'token',
    initialState: {
        accessToken: null
    },
    reducers: {
        clearToken(state) {
            return { ...state, accessToken: null };
        },
        setToken(state, action) {
            return { ...state, accessToken: action.payload };
        }
    }
});

export const { setToken, clearToken } = tokenSlice.actions;
export default tokenSlice.reducer;
