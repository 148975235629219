// material-ui
import { Box } from '@mui/material';

// project import
import NavItem from './NavItem';
import menuItem from 'menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    return (
        <Box sx={{ pt: 2 }}>
            {menuItem.items.map((item) => (
                <NavItem key={item.id} item={item} level={1} />
            ))}
        </Box>
    );
};

export default Navigation;
