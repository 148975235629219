import { Box, Typography, Avatar, Link } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import PropTypes from 'prop-types'; // Import PropTypes

const Success = ({ formData }) => (
    <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h5" gutterBottom>
            Application completed!
        </Typography>
        <Box sx={{ backgroundColor: '#e8f5e9', borderRadius: '16px', p: 4, mt: 2, textAlign: 'center' }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Thank you {formData.firstName}!
            </Typography>
            <Typography variant="subtitle1">Your application has been received. We look forward to reviewing your application.</Typography>
            <Box sx={{ mt: 2 }}>
                <Typography variant="body1" gutterBottom>
                    Connect with us!
                </Typography>
                <Typography variant="body2">Follow us on social media</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                    <Link href="https://www.linkedin.com/company/ozbul-it/" target="_blank" rel="noopener noreferrer">
                        <Avatar sx={{ bgcolor: 'primary.main', m: 1 }}>
                            <LinkedInIcon />
                        </Avatar>
                    </Link>
                    <Link href="https://www.instagram.com/ozbulbilisim/" target="_blank" rel="noopener noreferrer">
                        <Avatar sx={{ bgcolor: '#ff025b', m: 1 }}>
                            <InstagramIcon />
                        </Avatar>
                    </Link>
                </Box>
            </Box>
        </Box>
    </Box>
);
Success.propTypes = {
    formData: PropTypes.shape({
        firstName: PropTypes.string.isRequired
    }).isRequired
};
export default Success;
