import AdminTable from 'pages/ApplicantsPage';
import MainLayout from 'layout/MainLayout/index';
import LoginPage from 'pages/Login';
import Apply from 'pages/Apply';
import ApplicationGroupsTable from 'pages/ApplicationGroupsTable';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: 'admin',
            element: <MainLayout />,
            children: [
                { path: 'applicants', element: <AdminTable /> },
                { path: 'application-groups', element: <ApplicationGroupsTable /> }
            ]
        },
        {
            path: 'login',
            element: <LoginPage />
        },
        {
            path: 'apply',
            element: <Apply />
        }
    ]
};

export { MainRoutes };
