import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Paper, Stepper, Step, StepLabel, Box } from '@mui/material';

const StepperContainer = ({ activeStep, steps, renderStepContent }) => {
    return (
        <Paper elevation={1} sx={{ p: 2, mt: 4, mb: 4 }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ bgcolor: '#f6f6f6', m: 2, borderRadius: '8px', p: 2 }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{ mt: 2, mb: 2 }}>{renderStepContent(activeStep)}</Box>
        </Paper>
    );
};
StepperContainer.propTypes = {
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderStepContent: PropTypes.func.isRequired
};
export default StepperContainer;
