import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { setToken } from '../store/reducers/tokenSlice'; // Adjust the import to your file structure

export default function LogoutButton() {
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(setToken(null));
    };

    return (
        <Button variant="contained" color="error" onClick={handleLogout}>
            Logout
        </Button>
    );
}
