import React from 'react';
import { Box, Typography, TextField, Button, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { MuiPhone } from 'components/test'; // Ensure this component supports the required props
import 'react-international-phone/style.css';
import PropTypes from 'prop-types';

const ApplicationForm = ({
    formData,
    handleInputChange,
    setFormData,
    whatsappChecked,
    setWhatsappChecked,
    whatsappNumber,
    setWhatsappNumber,
    activeStep,
    handleBack,
    handleNext,
    isFormValid
}) => (
    <Box component="form" noValidate autoComplete="off">
        <Typography variant="body2" sx={{ mt: 2 }}>
            All fields are compulsory
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    error={!formData.firstName}
                    helperText={!formData.firstName && 'First Name is required'}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    error={!formData.lastName}
                    helperText={!formData.lastName && 'Last Name is required'}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!formData.email}
                    helperText={!formData.email && 'Email is required'}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    fullWidth
                    label="GitHub Link"
                    name="githubLink"
                    value={formData.githubLink}
                    onChange={handleInputChange}
                    error={!formData.githubLink}
                    helperText={!formData.githubLink && 'GitHub Link is required'}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <MuiPhone
                    required
                    fullWidth
                    defaultCountry="tr"
                    value={formData.phoneNumber}
                    onChange={(phone) => setFormData({ ...formData, phoneNumber: phone })}
                    // You'll need to handle error state within your MuiPhone component
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{ pl: 2 }}
                            checked={whatsappChecked}
                            onChange={(e) => setWhatsappChecked(e.target.checked)}
                            name="whatsappCheckbox"
                            color="primary"
                        />
                    }
                    label="I want to add my WhatsApp number as well"
                />
                {whatsappChecked && (
                    <MuiPhone
                        required
                        fullWidth
                        defaultCountry="ua"
                        value={whatsappNumber}
                        onChange={(phone) => setWhatsappNumber(phone)}
                        sx={{ mt: 2 }}
                        // Again, ensure your MuiPhone can display error/validation messages
                    />
                )}
            </Grid>
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
            </Button>

            <Button variant="contained" color="primary" disabled={!isFormValid} onClick={handleNext}>
                Continue
            </Button>
        </Box>
    </Box>
);

ApplicationForm.propTypes = {
    formData: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    setFormData: PropTypes.func.isRequired,
    whatsappChecked: PropTypes.bool.isRequired,
    setWhatsappChecked: PropTypes.func.isRequired,
    whatsappNumber: PropTypes.string.isRequired,
    setWhatsappNumber: PropTypes.func.isRequired,
    activeStep: PropTypes.number.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    isFormValid: PropTypes.bool.isRequired
};

export default ApplicationForm;
