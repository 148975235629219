import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    CardActionArea,
    Paper,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Slide } from '../../node_modules/@mui/material/index';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types'; // Import PropTypes
import axios from 'axios';

const itemsPerPage = 3;

const LandingPage = ({ onJobSelect }) => {
    const [currentPage, setCurrentPage] = useState(0);

    const [selectedGroup, setSelectedGroup] = useState({});
    const [slideDirection, setSlideDirection] = useState('left');
    const slideContainerRef = useRef(null); // Initialize the ref
    const [openDialog, setOpenDialog] = useState(false);
    const [applicationGroups, setApplicationGroups] = useState([]); // Adjusted to fetch data dynamically
    const totalPages = Math.ceil(applicationGroups.length / itemsPerPage);
    const token =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTiIsImV4cCI6MTcxMTM0OTc5OCwiaXNzIjoieW91cl9pc3N1ZXIiLCJhdWQiOiJ5b3VyX2F1ZGllbmNlIn0.je1pdfrfksXe22bpq2Xn6Bh0g5u5_lrN_nXJyTNWtNE';
    const [isLoading, setLoading] = useState(false);
    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setSlideDirection('left');
            setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1));
        }
    };
    useEffect(() => {
        const fetchApplicationGroups = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/api/ApplicationGroup/active`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setApplicationGroups(response.data); // Assuming the API returns an array of application groups
            } catch (error) {
                console.error('Failed to fetch application groups:', error);
                // Optionally handle the error (e.g., show a notification)
            }
            setLoading(false);
        };

        fetchApplicationGroups();
    }, []);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleOpenDialog = (group) => {
        if (!isLoading) console.log('Succesfully fetched');
        setSelectedGroup(group);
        setOpenDialog(true);
    };
    const handleApply = () => {
        onJobSelect(selectedGroup.id, selectedGroup.name);
        handleCloseDialog(); // Close the dialog after triggering the navigation
    };
    const handlePrevious = () => {
        if (currentPage > 0) {
            setSlideDirection('right');
            setCurrentPage((prev) => Math.max(prev - 1, 0));
        }
    };
    const logoUrl = 'https://ozbul.com/wp-content/uploads/2020/09/Ozbul-logo-04.png';
    const displayedGroups = applicationGroups.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    return (
        <Paper elevation={2} sx={{ p: 2, mt: 4, mb: 4, overflow: 'hidden' }}>
            <img src={logoUrl} alt="Company Logo" style={{ maxWidth: '100px', marginBottom: '10px', alignItems: 'center' }} />
            <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
                {"Welcome to Our Company's Job Applications"}
            </Typography>
            <Typography variant="subtitle1" sx={{ textAlign: 'center', mb: 4 }}>
                Explore the available positions and apply to join our team.
            </Typography>
            <Box sx={{ overflow: 'hidden' }} ref={slideContainerRef}>
                <Paper
                    elevation={1}
                    sx={{
                        p: 2,
                        mt: 4,
                        mb: 4,
                        height: '100%',
                        backgroundColor: '#2626260a',
                        boxShadow:
                            'inset 0px 0px 1px 0px rgb(0 0 0 / 30%), inset 0px 0px 8px 0px rgb(0 0 0 / 31%), 0px 0px 3px 0px rgb(0 0 0 / 33%)'
                    }}
                >
                    <Slide
                        direction={slideDirection}
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        key={currentPage}
                        container={slideContainerRef.current}
                    >
                        <div>
                            {' '}
                            <Grid container justifyContent="center" spacing={2}>
                                {displayedGroups.map((group) => (
                                    <Grid item key={group.id} xs={4}>
                                        <Card
                                            sx={{
                                                boxShadow:
                                                    'outset -2px -3px 1px 0px rgb(0 0 0 / 3%), inset -3px -2px 20px 4px rgb(0 0 0 / 31%), 0px 1px 3px 0px rgb(0 0 0 / 33%)',
                                                height: '100%', // Ensure uniform height
                                                display: 'flex',
                                                flexDirection: 'column',
                                                minHeight: '160px',
                                                maxHeight: '160px'
                                            }}
                                        >
                                            <CardActionArea
                                                onClick={() => handleOpenDialog(group)}
                                                sx={{
                                                    height: '100%', // Ensure uniform height
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    minHeight: '160px'
                                                }}
                                            >
                                                <CardContent>
                                                    <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                                                        {group.name}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            display: '-webkit-box',
                                                            overflow: 'hidden',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 2, // Limit to 2 lines. Adjust as needed.
                                                            textOverflow: 'ellipsis'
                                                        }}
                                                    >
                                                        {group.description}
                                                    </Typography>

                                                    <Typography variant="body2" sx={{ mt: 'auto' }}>
                                                        Apply before: {group.endDate}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                        <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="group-dialog-title">
                                            <DialogTitle sx={{ mt: 4 }} variant="h2" id="group-dialog-title">
                                                {selectedGroup.name}
                                            </DialogTitle>
                                            <DialogContent sx={{ minHeight: '400px', minWidth: '500px' }}>
                                                <DialogContentText>{selectedGroup.description}</DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Box sx={{ width: '100%', padding: 1 }}>
                                                    <Button
                                                        onClick={handleApply}
                                                        color="primary"
                                                        variant="contained"
                                                        sx={{ width: '100%' }} // Make the button full width
                                                    >
                                                        Apply
                                                    </Button>
                                                </Box>
                                                <Button
                                                    onClick={handleCloseDialog}
                                                    color="error"
                                                    sx={{ position: 'absolute', right: 8, top: 8 }}
                                                >
                                                    <CloseIcon />
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Slide>
                </Paper>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
                <Button onClick={handlePrevious} disabled={currentPage === 0}>
                    <ArrowBackIosIcon />
                </Button>
                <Button onClick={handleNext} disabled={currentPage >= totalPages - 1}>
                    <ArrowForwardIosIcon />
                </Button>
            </Box>
        </Paper>
    );
};
LandingPage.propTypes = {
    onJobSelect: PropTypes.object.isRequired
};
export default LandingPage;
