import React from 'react';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setToken } from '../store/reducers/tokenSlice';

export default function ApproveButton({ name, id, triggerFetch }) {
    const token =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTiIsImV4cCI6MTcxMTM0OTc5OCwiaXNzIjoieW91cl9pc3N1ZXIiLCJhdWQiOiJ5b3VyX2F1ZGllbmNlIn0.je1pdfrfksXe22bpq2Xn6Bh0g5u5_lrN_nXJyTNWtNE';
    const dispatch = useDispatch();
    const url = `/api/jobapplication/status`;
    const handleApprove = () => {
        console.log(token);
        Swal.fire({
            title: 'Confirmation',
            text: `Are you sure you want to approve ${name}'s application?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, approve it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const formData = new FormData();
                    formData.append('Id', id);
                    formData.append('Status', 2);

                    await axios.post(url, formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    Swal.fire('Approved!', 'The application has been approved.', 'success');
                    triggerFetch();
                } catch (error) {
                    console.error('An error occurred while retrieving the data:', error);

                    // Check if error response exists and log it
                    if (error.response) {
                        console.error('Server Response:', error.response.data);
                    }

                    if (error.response && error.response.status === 401) {
                        dispatch(setToken(null));
                    }
                    Swal.fire('Error!', 'An error occurred while approving the application.', 'error');
                }
            }
        });
    };

    return (
        <Button variant="contained" color="primary" onClick={handleApprove}>
            Approve
        </Button>
    );
}

ApproveButton.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    triggerFetch: PropTypes.func
};
