import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Box, Typography, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
const UploadFiles = ({
    uploadedFiles,
    handleFileSelect,
    handleFileDrop,
    handleDeleteFile,
    allFilesUploaded,
    setActiveStep,
    openFileDialog
}) => (
    <Box>
        <Typography variant="body2" sx={{ mt: 2 }}>
            All fields are compulsory
        </Typography>
        <Box>
            <Typography variant="h3" component="h3" fontWeight="bold" sx={{ marginTop: 2 }}>
                Upload Resumé
            </Typography>
            <Box
                sx={{
                    border: '2px dashed blue',
                    padding: 2,
                    marginTop: 2,
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '50px',
                    position: 'relative'
                }}
                onDrop={(e) => handleFileDrop(e, 'resume')}
                onDragOver={(e) => e.preventDefault()}
                onClick={() => openFileDialog('resume-file-input')}
            >
                {!uploadedFiles.resume && <Typography>Drag and drop the file here or click to select file</Typography>}
                {uploadedFiles.resume && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <InsertDriveFileIcon color="action" />
                        <Typography variant="caption" sx={{ ml: 1 }}>
                            {uploadedFiles.resume.name}
                        </Typography>
                        <DeleteIcon color="error" onClick={() => handleDeleteFile('resume')} />
                    </Box>
                )}
                <input id="resume-file-input" type="file" hidden onChange={(e) => handleFileSelect(e, 'resume')} />
            </Box>
            <Typography variant="h3" component="h3" fontWeight="bold" sx={{ marginTop: 2 }}>
                Upload Transcript
            </Typography>
            <Box
                sx={{
                    border: '2px dashed blue',
                    padding: 2,
                    marginTop: 2,
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '50px',
                    position: 'relative'
                }}
                onDrop={(e) => handleFileDrop(e, 'transcript')}
                onDragOver={(e) => e.preventDefault()}
                onClick={() => openFileDialog('transcript-file-input')}
            >
                {!uploadedFiles.transcript && <Typography>Drag and drop the file here or click to select file</Typography>}
                {uploadedFiles.transcript && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <InsertDriveFileIcon color="action" />
                        <Typography variant="caption" sx={{ ml: 1 }}>
                            {uploadedFiles.transcript.name}
                        </Typography>
                        <DeleteIcon color="error" onClick={() => handleDeleteFile('transcript')} />
                    </Box>
                )}
                <input id="transcript-file-input" type="file" hidden onChange={(e) => handleFileSelect(e, 'transcript')} />
            </Box>
        </Box>

        <Typography variant="caption" display="block" gutterBottom sx={{ mt: 2 }}>
            Acceptable file types: .doc, .docx, .pdf, and .txt (5MB max)
        </Typography>
        <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            disabled={!allFilesUploaded}
            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
        >
            Continue
        </Button>
    </Box>
);
UploadFiles.propTypes = {
    uploadedFiles: PropTypes.shape({
        resume: PropTypes.shape({
            name: PropTypes.string
        }),
        transcript: PropTypes.shape({
            name: PropTypes.string
        })
    }),
    handleFileSelect: PropTypes.func.isRequired,
    handleFileDrop: PropTypes.func.isRequired,
    handleDeleteFile: PropTypes.func.isRequired,
    allFilesUploaded: PropTypes.bool.isRequired,
    setActiveStep: PropTypes.func.isRequired,
    openFileDialog: PropTypes.func.isRequired
};
export default UploadFiles;
