import React, { useState, useEffect } from 'react';
import { Container, Slide } from '@mui/material';
import 'react-international-phone/style.css';
import UploadFiles from 'components/UploadFiles';
import ApplicationForm from 'components/ApplicationForm';
import Review from 'components/Review';
import Success from 'components/Success';
import JobDescriptionHeader from 'components/JobDescriptionHeader';
import StepperContainer from 'components/Stepper';
import LandingPage from './LandingPage';

import './transitionStyles.css';

import { AnimatePresence } from 'framer-motion';
import axios from 'axios';
import Swal from 'sweetalert2';
const getSteps = () => {
    return ['Upload files', 'Application form', 'Review', 'Success'];
};

const Apply = () => {
    const [submitting, setSubmitting] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [showLandingPage, setShowLandingPage] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState({
        resume: null,
        transcript: null
    });
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedGroupName, setSelectedGroupName] = useState('');
    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        githubLink: ''
    });

    const handleJobSelect = (groupId, groupName) => {
        setShowLandingPage(false);
        setSelectedGroupName(groupName);
        setSelectedGroupId(groupId); // Save the selected group's ID
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setIsFormValid(validateForm(formData));
    };

    const [formData, setFormData] = useState({
        phoneNumber: '',
        firstName: '',
        lastName: '',
        email: '',
        githubLink: ''
    });
    const validateForm = () => {
        if (formErrors) console.log('An error has occured validating the form');
        const errors = {};
        if (!formData.firstName.trim()) errors.firstName = 'First name is required';
        if (!formData.lastName.trim()) errors.lastName = 'Last name is required';
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'Email is not valid';
        }
        if (!formData.githubLink.trim()) errors.githubLink = 'Github link is required';
        if (!formData.phoneNumber.trim()) errors.phoneNumber = 'Phone number is required';
        setFormErrors(errors);
        return Object.keys(errors).length === 0; // Form is valid if there are no errors
    };
    const handleFormSubmit = async (e) => {
        if (submitting) console.log('Submitting');
        e.preventDefault();

        const Test = new FormData();

        if (uploadedFiles.resume) Test.append('ResumeFile', uploadedFiles.resume);
        if (uploadedFiles.transcript) Test.append('TranscriptFile', uploadedFiles.transcript);

        Test.append('Name', formData.firstName);
        Test.append('Surname', formData.lastName);
        Test.append('Email', formData.email);
        Test.append('PhoneNumber', formData.phoneNumber);
        Test.append('GitHubLink', formData.githubLink);
        // Assuming you have a way to get the selected group ID
        Test.append('GroupId', selectedGroupId); // Make sure to set selectedGroupId when a group is selected
        console.log(Test.firstName);
        try {
            await axios.post('/api/JobApplication', Test, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire('Submission successful!', '', 'success');
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } catch (error) {
            Swal.fire(`Error: ${error.response?.data?.message || 'Something went wrong'}`, '', 'error');
            // Additional error handling here
        } finally {
            setSubmitting(false);
        }
    };
    const [whatsappChecked, setWhatsappChecked] = useState(false);
    const [whatsappNumber, setWhatsappNumber] = useState('');

    const handleDeleteFile = (fileType) => {
        setUploadedFiles({ ...uploadedFiles, [fileType]: null });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFileSelect = (event, fileType) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFiles({ ...uploadedFiles, [fileType]: file });
        }
    };

    const handleFileDrop = (event, fileType) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            setUploadedFiles({ ...uploadedFiles, [fileType]: file });
        }
    };

    const allFilesUploaded = uploadedFiles.resume && uploadedFiles.transcript;
    const openFileDialog = (fileInputId) => {
        const fileInput = document.getElementById(fileInputId);
        fileInput.click();
    };
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };
    const handleNext = () => {
        if (validateForm()) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    useEffect(() => {
        // Store the original body styles to revert back to them when the component unmounts
        const originalBodyStyle = document.body.style.cssText;

        // Set the background image for the entire page
        document.body.style.cssText = `
            background-image: url("https://img.freepik.com/premium-vector/vector-abstract-modern-background-illustration-technology-business-theme-wallpaper-vertical-orientation_554888-951.jpg?w=360");
            background-size: cover;
            background-position: center;
            background-attachment: fixed; // Optional: Prevents background from scrolling
            margin: 0; // Remove default margin
            height: 100vh; // Full height
            display: flex; // Enable flex container
            justify-content: center; // Center horizontally
            align-items: center; // Center vertically
        `;

        // Revert back to the original body styles on component unmount
        return () => {
            document.body.style.cssText = originalBodyStyle;
        };
    }, []);

    const renderStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <UploadFiles
                        uploadedFiles={uploadedFiles}
                        handleFileSelect={handleFileSelect}
                        handleFileDrop={handleFileDrop}
                        handleDeleteFile={handleDeleteFile}
                        allFilesUploaded={allFilesUploaded}
                        setActiveStep={setActiveStep}
                        openFileDialog={openFileDialog}
                    />
                );
            case 1:
                return (
                    <ApplicationForm
                        formData={formData}
                        handleInputChange={handleInputChange}
                        setFormData={setFormData}
                        whatsappChecked={whatsappChecked}
                        setWhatsappChecked={setWhatsappChecked}
                        whatsappNumber={whatsappNumber}
                        setWhatsappNumber={setWhatsappNumber}
                        activeStep={activeStep}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        allFilesUploaded={allFilesUploaded}
                        handleFormSubmit={handleFormSubmit}
                        steps={steps}
                        validateForm={validateForm}
                        isFormValid={isFormValid}
                    />
                );

            case 2:
                return (
                    <Review
                        uploadedFiles={uploadedFiles}
                        formData={formData}
                        whatsappChecked={whatsappChecked}
                        whatsappNumber={whatsappNumber}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        handleFormSubmit={handleFormSubmit}
                    />
                );

            case 3:
                return <Success formData={formData} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 15 }}>
            <AnimatePresence>
                {showLandingPage ? (
                    <Slide direction="right" in={showLandingPage} mountOnEnter unmountOnExit key="landing">
                        <div>
                            <LandingPage onJobSelect={handleJobSelect} />
                        </div>
                    </Slide>
                ) : (
                    <Slide direction="left" in={!showLandingPage} mountOnEnter unmountOnExit key="form">
                        <div>
                            <JobDescriptionHeader groupName={selectedGroupName} onGoBack={() => setShowLandingPage(true)} />
                            <StepperContainer activeStep={activeStep} steps={steps} renderStepContent={renderStepContent} />
                        </div>
                    </Slide>
                )}
            </AnimatePresence>
        </Container>
    );
};

export default Apply;
