import React from 'react';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setToken } from '../store/reducers/tokenSlice';

export default function RejectButton({ name, id, triggerFetch }) {
    const token = useSelector((state) => state.tokenSlice.accessToken);
    const dispatch = useDispatch();

    const url = `/api/jobapplication/status`;

    const handleReject = () => {
        Swal.fire({
            title: 'Confirmation',
            text: `Are you sure you want to reject ${name}'s application?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, reject it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const formData = new FormData();
                    formData.append('Id', id);
                    formData.append('Status', 3);

                    await axios.post(url, formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    Swal.fire('Rejected!', 'The application has been rejected.', 'success');
                    triggerFetch();
                } catch (error) {
                    console.error('An error occurred while retrieving the data:', error);

                    if (error.response && error.response.status === 401) {
                        dispatch(setToken(null));
                    }

                    Swal.fire('Error!', 'An error occurred while rejecting the application.', 'error');
                }
            }
        });
    };

    return (
        <Button variant="contained" color="error" onClick={handleReject}>
            Reject
        </Button>
    );
}

RejectButton.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    triggerFetch: PropTypes.func
};
