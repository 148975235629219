// project import
import { UserOutlined } from '@ant-design/icons';
import GroupsIcon from '@mui/icons-material/Groups';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        {
            id: 'applicants',
            title: 'Applicant List',
            navTitle: 'Applicants',
            url: '/admin/applicants',
            icon: UserOutlined
        },
        {
            id: 'application-groups',
            title: 'Application Groups List',
            navTitle: 'Application Groups',
            url: '/admin/application-groups',
            icon: GroupsIcon
        }
    ]
};

export default menuItems;
