import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch
} from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useSelector } from 'react-redux';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';

const getStatus = (startDate, endDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (today >= start && today <= end) return 'Open';
    if (today < start) return 'Not Opened Yet';
    if (today > end) return 'Closed';
};

const ApplicationGroupsTable = () => {
    const [groups, setGroups] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMode, setDialogMode] = useState('add');
    const [editGroupId, setEditGroupId] = useState(null);
    const [newGroup, setNewGroup] = useState({ name: '', startDate: '', endDate: '', numberOfApplicants: 0, description: '' });
    const [showOpenOnly, setShowOpenOnly] = useState(false);
    const [sortField, setSortField] = useState('startDate');
    const [sortDirection, setSortDirection] = useState('asc');
    const token = useSelector((state) => state.token);

    const fetchGroups = useCallback(async () => {
        try {
            const url = `/api/ApplicationGroup`;
            console.log(url);
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const fetchedGroups = response.data.map((group) => ({
                ...group,
                status: getStatus(group.startDate, group.endDate)
            }));
            setGroups(fetchedGroups);
        } catch (error) {
            console.error('Failed to fetch groups:', error);
        }
    }, [token]);

    useEffect(() => {
        fetchGroups();
    }, [fetchGroups]);

    const sortGroups = (field, direction) => {
        const sortedGroups = [...groups].sort((a, b) => {
            if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
            if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setGroups(sortedGroups);
    };

    const handleSort = (field) => {
        const isAsc = sortField === field && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortField(field);
        sortGroups(field, isAsc ? 'desc' : 'asc');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewGroup({ ...newGroup, [name]: value });
    };

    const handleOpenDialogForAdd = () => {
        setDialogMode('add');
        setNewGroup({ name: '', startDate: '', endDate: '', numberOfApplicants: 0, description: '' });
        setOpenDialog(true);
    };

    const handleOpenDialogForEdit = (group) => {
        setDialogMode('edit');
        setEditGroupId(group.id);

        // Format the dates
        const formattedStartDate = new Date(group.startDate).toISOString().split('T')[0];
        const formattedEndDate = new Date(group.endDate).toISOString().split('T')[0];

        setNewGroup({
            ...group,
            startDate: formattedStartDate,
            endDate: formattedEndDate
        });

        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleAddOrUpdateGroup = async () => {
        const url = `/api/ApplicationGroup${dialogMode === 'edit' ? '/update' : ''}`;
        const method = dialogMode === 'edit' ? 'post' : 'post'; // Update this if necessary
        const formData = new FormData();
        formData.append('Name', newGroup.name);
        formData.append('Description', newGroup.description);
        formData.append('StartDate', newGroup.startDate);
        formData.append('EndDate', newGroup.endDate);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        if (dialogMode === 'edit' && editGroupId != null) {
            formData.append('Id', editGroupId.toString());
        }
        try {
            await axios({ method, url, data: formData, ...config });
            fetchGroups(); // Refresh the list
        } catch (error) {
            console.error(`Failed to ${dialogMode === 'edit' ? 'update' : 'add'} group:`, error);
        }

        handleCloseDialog();
    };

    const handleDelete = async (groupId) => {
        try {
            await axios.post(
                `/api/ApplicationGroup/delete/${groupId}`,
                {},
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            setGroups(groups.filter((group) => group.id !== groupId));
        } catch (error) {
            console.error('Failed to delete group:', error);
        }
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Application Groups
            </Typography>
            <Button variant="contained" onClick={handleOpenDialogForAdd}>
                <GroupAddIcon />
                <Typography sx={{ ml: 1 }}> Add Group </Typography>
            </Button>
            <Switch
                sx={{ ml: 97 }}
                checked={showOpenOnly}
                onChange={(event) => setShowOpenOnly(event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography component="span">Show Open Only</Typography>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{dialogMode === 'edit' ? 'Edit Application Group' : 'Add a New Application Group'}</DialogTitle>
                <DialogContent dividers>
                    <DialogContent dividers>
                        <TextField
                            margin="dense"
                            name="name"
                            label="Name"
                            fullWidth
                            variant="outlined"
                            value={newGroup.name}
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            margin="dense"
                            name="description"
                            label="Description"
                            fullWidth
                            variant="outlined"
                            value={newGroup.description}
                            onChange={handleChange}
                            multiline // Allow multiple lines
                            rows={4} // Adjust based on your preference
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            margin="dense"
                            name="startDate"
                            label="Start Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                            value={newGroup.startDate}
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            margin="dense"
                            name="endDate"
                            label="End Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                            value={newGroup.endDate}
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                    </DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleAddOrUpdateGroup}>{dialogMode === 'edit' ? 'Update' : 'Add'}</Button>
                </DialogActions>
            </Dialog>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell onClick={() => handleSort('startDate')} style={{ cursor: 'pointer' }}>
                                Start Date {sortField === 'startDate' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </TableCell>
                            <TableCell onClick={() => handleSort('endDate')} style={{ cursor: 'pointer' }}>
                                End Date {sortField === 'endDate' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </TableCell>
                            <TableCell onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                                Status {sortField === 'status' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </TableCell>
                            <TableCell>Number of Applicants</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groups
                            .filter((group) => !showOpenOnly || group.status === 'Open') // Apply the filtering based on showOpenOnly state
                            .map((group) => (
                                <TableRow key={group.id}>
                                    <TableCell>{group.name}</TableCell>
                                    <TableCell
                                        style={{
                                            maxWidth: '200px', // Adjust as needed
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                        title={group.description} // Show full description on hover
                                    >
                                        {group.description}
                                    </TableCell>
                                    <TableCell>{group.startDate}</TableCell>
                                    <TableCell>{group.endDate}</TableCell>
                                    <TableCell>{group.status}</TableCell>
                                    <TableCell>{group.numberOfApplicants}</TableCell>
                                    <TableCell>
                                        <Button color="primary" variant="contained" onClick={() => handleOpenDialogForEdit(group)}>
                                            <EditIcon />
                                            <Typography sx={{ ml: 1 }}> Edit</Typography>
                                        </Button>
                                        <Button color="error" variant="contained" onClick={() => handleDelete(group.id)} sx={{ ml: 1 }}>
                                            <GroupRemoveIcon />
                                            <Typography sx={{ ml: 1 }}> Delete </Typography>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ApplicationGroupsTable;
