import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { openDrawer } from 'store/reducers/menu';
import { useNavigate } from 'react-router';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.tokenSlice.accessToken);
    const navigate = useNavigate();
    const location = useLocation();
    const { drawerOpen } = useSelector((state) => state.menu);
    const [showTokenInput, setShowTokenInput] = useState(false);
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
    }, [drawerOpen, open]);

    useEffect(() => {
        if (!token) {
            setShowTokenInput(true);
            return;
        }
    }, [token, navigate, location.pathname]);

    if (showTokenInput) {
        navigate('/login');
    }
    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="main"
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flexGrow: 1,
                    p: { xs: 2, sm: 3 }
                }}
            >
                <Box sx={{ width: '100%', maxWidth: '1200px' }}>
                    <Toolbar />
                    <Breadcrumbs card={'false'} divider={false} />
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};

export default MainLayout;
