import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, TextField, Button, Typography, Box, Link, Grid } from '@mui/material';
import companyLogo from '../components/compnayLogo.png'; // Make sure this path is correct
import { setToken } from '../store/reducers/tokenSlice'; // Import the setToken action from the token slice
import { useNavigate } from 'react-router';
function LoginPage() {
    const dispatch = useDispatch(); // Initialize the useDispatch hook

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email');
        const requestPassword = formData.get('password');

        setLoading(true);

        try {
            console.log(JSON.stringify({ email, requestPassword }));
            const response = await fetch('/api/Admin/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, requestPassword })
            });

            if (!response.ok) {
                throw new Error('Login failed: Incorrect email or password.');
            }

            const data = await response.json();
            console.log(data);
            dispatch(setToken(data));

            navigate('/admin');

            // Dispatch the setToken action with the token payload

            // Redirect to the desired page, e.g., admin page
            // You can use useHistory or useNavigate for navigation
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: 3,
                    p: 4,
                    borderRadius: 1,
                    backgroundColor: 'white'
                }}
            >
                <Box component="img" src={companyLogo} alt="Company Logo" sx={{ height: 80, mb: 2 }} />
                <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                    Sign in
                </Typography>

                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email or Phone"
                        name="email"
                        autoComplete="email"
                        variant="outlined"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        variant="outlined"
                    />
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                    {error && (
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    )}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>
                        {loading ? 'Signing in...' : 'Sign in'}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default LoginPage;
