import { useEffect, useState } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';

// material-ui
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Grid, Typography } from '@mui/material';

// project imports
import MainCard from '../MainCard';
import breadcrumbRoutes from 'routes/Breadcrumbs';

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ ...others }) => {
    const location = useLocation();
    const [item, setItem] = useState();

    let breadcrumbContent = <Typography />;

    useEffect(() => {
        const foundItem = breadcrumbRoutes.find((route) => {
            if (matchPath({ path: route.url }, location.pathname)) {
                return route;
            }
        });
        setItem(foundItem);
    }, [location.pathname]);

    // items
    if (item) {
        // main
        breadcrumbContent = (
            <MainCard border={'none'} sx={{ mb: 3, bgcolor: 'transparent' }} {...others} content={false}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <MuiBreadcrumbs aria-label="breadcrumb">
                            <Typography component={Link} to="/" color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
                                Home
                            </Typography>
                            {item.paths.map((path, i) => {
                                if (i === item.paths.length - 1) {
                                    return (
                                        <Typography key={i} variant="subtitle1" color="textPrimary">
                                            {path}
                                        </Typography>
                                    );
                                }
                                return (
                                    <Typography key={i} variant="h6" color="textSecondary">
                                        {path}
                                    </Typography>
                                );
                            })}
                        </MuiBreadcrumbs>
                    </Grid>
                    <Grid item sx={{ mt: 2 }}>
                        <Typography variant="h2">{item.title}</Typography>
                    </Grid>
                </Grid>
            </MainCard>
        );
    }

    return breadcrumbContent;
};

export default Breadcrumbs;
