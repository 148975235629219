import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Box, Typography, Button } from '@mui/material';

const JobDescriptionHeader = ({ groupName, onGoBack }) => {
    return (
        <Box
            sx={{
                p: 2,
                border: '1px solid #978cfa',
                borderRadius: '10px',
                backgroundColor: '#ffffff',
                marginTop: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2
            }}
        >
            <Typography variant="h6">Join us at Ozbul Bilisim as a</Typography>
            <Typography variant="h2" component="h1" fontWeight="bold">
                {groupName}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: 1
                }}
            ></Box>
            <Box>
                <Typography variant="h6" sx={{ ml: 1 }}>
                    Miss Clicked? No worries {':)'}
                </Typography>
                <Button variant="text" sx={{ alignSelf: 'center' }} onClick={onGoBack}>
                    Go back to available jobs
                </Button>
            </Box>
        </Box>
    );
};
JobDescriptionHeader.propTypes = {
    groupName: PropTypes.string.isRequired,
    onGoBack: PropTypes.func.isRequired
};
export default JobDescriptionHeader;
