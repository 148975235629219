import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Box, Typography, Button, Paper, Grid } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
const Review = ({ uploadedFiles, formData, whatsappChecked, whatsappNumber, handleBack, handleFormSubmit }) => (
    <Box sx={{ my: 2 }}>
        <Paper elevation={2} sx={{ bgcolor: '#f6f6f6', p: 2, m: 2 }}>
            <Typography variant="subtitle1" gutterBottom sx={{ mt: 1, mb: 2 }}>
                Uploaded Files:
            </Typography>
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6}>
                    <Typography>
                        <InsertDriveFileIcon color="error" sx={{ verticalAlign: 'bottom', mr: 1 }} />
                        {uploadedFiles.resume ? uploadedFiles.resume.name : 'No file selected'}{' '}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography>
                        <InsertDriveFileIcon color="error" sx={{ verticalAlign: 'bottom', mr: 1 }} />
                        {uploadedFiles.resume ? uploadedFiles.transcript.name : 'No file selected'}{' '}
                    </Typography>
                </Grid>
            </Grid>
            <Typography variant="subtitle1" gutterBottom>
                Personal Details:
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography>Name:</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {formData.firstName} {formData.lastName}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography>Email:</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {formData.email}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography>Phone Number:</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {formData.phoneNumber}
                    </Typography>
                </Grid>
                {whatsappChecked && whatsappNumber && (
                    <Grid item xs={12} sm={6}>
                        <Typography>WhatsApp Number:</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {whatsappNumber}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} sm={6}>
                    <Typography>GitHub Link:</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {formData.githubLink}
                    </Typography>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Button variant="outlined" onClick={handleBack}>
                    Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                    Submit Application
                </Button>
            </Box>
        </Paper>
    </Box>
);
Review.propTypes = {
    uploadedFiles: PropTypes.shape({
        resume: PropTypes.shape({
            name: PropTypes.string
        }),
        transcript: PropTypes.shape({
            name: PropTypes.string
        })
    }),
    formData: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        githubLink: PropTypes.string
    }),
    whatsappChecked: PropTypes.bool,
    whatsappNumber: PropTypes.string,
    handleBack: PropTypes.func.isRequired,
    handleFormSubmit: PropTypes.func.isRequired
};
export default Review;
